<template>
    <div class="">
      <ul class="areas-body clearfix">
        <li class="area-item" v-for="(item, pindex) in cityData" :key="item.value">
          <div class="checkbox">
            <label>
              <el-checkbox v-model="item.checked" :indeterminate="item.indeterminate" @change="checked=>chooseParent(checked,pindex)">{{item.label}}</el-checkbox>
              <span v-show="checked_length(pindex)>0" class="clr-orange">
                ({{checked_length(pindex)}})
              </span>
            </label>
          </div>
          <div class="citys">
            <div class="checkbox" v-for="(val,index) in item.children" :key="val.value">
              <label>
                <el-checkbox v-model="val.checked" @change="checked=>chooseChild(checked,pindex,index)">{{val.label}}</el-checkbox>
              </label>
            </div>
          </div>
          <div class="" style="clear: both;">
          </div>
        </li>
      </ul>
      <el-button type="primary" size="small" @click="chooseOk()">确定</el-button>
    </div>
</template>
<script>
    export default {
      data () {
        return {
          cityData: [],
          selectCity: {}
        }
      },
      props: ['callFunc', 'selectedRegions'],
      watch: {
        selectedRegions (newValue,oldValue) {
          console.log(newValue)
          this.initCity(newValue)
        }
      },
      mounted () {
        this.getCityTree()
      },
      methods: {
        initCity(citys) {
          // if(!citys || citys.length<1) {
          //   return false
          // }
          if(this.cityData.length>0) {
            this.cityData.filter(function (item) {
              item.checked = false
              item.indeterminate = false
              item.children.filter(function (child) {
                item.checked = false
              })
              if(citys && citys.indexOf(item.value)>-1) {
                item.checked = true
                item.children.filter(function (child) {
                  child.checked = true
                })
              } else {
                var checkedNum = 0
                item.children.filter(function (child) {
                  if(citys.indexOf(child.value)>-1) {
                    child.checked = true
                    checkedNum ++
                  }
                })
                if(checkedNum == item.children.length) {
                  item.checked = true
                } else if(checkedNum>0) {
                  item.indeterminate = true
                }
              }
            })
          }
        },
        chooseParent(checked,idx) {
          this.cityData[idx].indeterminate = false
          this.cityData[idx].children.filter(function (item) {
            item.checked = checked
            return item
          })
        },
        chooseChild(e,pidx,idx) {
          var checkNum = 0
          this.cityData[pidx].children.forEach(function (item) {
            if(item.checked) {
              checkNum ++
            }
          })
          if(checkNum==0) {
            this.cityData[pidx].checked = false
            this.cityData[pidx].indeterminate = false
          } else if(checkNum==this.cityData[pidx].children.length) {
            this.cityData[pidx].checked = true
            this.cityData[pidx].indeterminate = false
          } else {
            this.cityData[pidx].checked = false
            this.cityData[pidx].indeterminate = true
          }
        },
        getCityTree() {
          this.$api.merchant.cityTree(res => {
            if(res.errcode == 0) {
              var cityData = res.data
              this.cityData = cityData.filter(function (item) {
                item.checked = false
                item.indeterminate = false
                item.children = item.children.filter(function (child) {
                  child.checked = false
                  return child
                })
                return item
              })
              this.initCity(this.selectedRegions)
            }
          })
        },
        chooseOk() {
          var cityIds = []
          var cityStrings = []
          this.cityData.forEach(function (item) {
            if(item.checked) {
              cityIds.push(item.value)
              cityStrings.push(item.label)
            } else if(item.indeterminate) {
              item.children.forEach(function (child) {
                if(child.checked) {
                  cityIds.push(child.value)
                  cityStrings.push(child.label)
                }
              })
            }
          })
          this.callFunc({
            cityIds: cityIds,
            cityStrings: cityStrings.join('，')
          })
        },
        checked_length (idx) {
          let i = 0;
          this.cityData[idx].children.forEach((value, index) => {
            if (value.checked) {
              i++;
            }
          });
          return i;
        }
      }
    }
</script>
<style scoped>
.areas-body {
  height: 250px;
}
.areas-body .area-item {
  height: 30px;
  line-height: 30px;
  width: 142px;
  float: left;
  position: relative;
  bottom: -1px;
}
.areas-body .area-item .checkbox label {
  padding: 0 10px 0 0;
}
.areas-body .area-item:hover > .checkbox {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: #FFFEC6;
  border: 0 solid #f7e4a5;
  border-width: 1px 1px 0 1px;
}
.areas-body .area-item:hover .citys {
  display: block;
}
.areas-body .area-item .citys {
  background-color: #FFFEC6;
  position: absolute;
  float: right;
  border: solid 1px #f7e4a5;
  padding: 4px 0;
  top: 30px;
  left: 0;
  z-index: 8;
  width: 220px;
  white-space: nowrap;
  display: none;
}
.areas-body .area-item .citys .checkbox {
  float: left;
  min-width: 108px;
}
.areas-body .area-item .citys .checkbox + .checkbox {
  margin: 0;
}

</style>
