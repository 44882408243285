<template>
  <div class="main-box">
    <el-form class="form-inline" :inline="true">
      <el-form-item label="">
        <el-input v-model="search.keywords" placeholder="请输入名称" size="small" clearable>
          <el-button slot="append" class="iv-search" size="small" type="primary" @click="handleSearch">搜索</el-button>
        </el-input>
      </el-form-item>
    </el-form>
    <div class="mg-bt-10">
      <el-button type="primary" size="small" icon="el-icon-plus" @click="showModal=true;form.devilery_id=0;form.devilery=''">添加</el-button>
      <el-button type="danger" size="small" icon="el-icon-delete" @click="delGroup()">删除</el-button>
    </div>
    <el-table
        :data="tableData"
        ref="rfTable"
        style="width: 100%"
        size="mini"
        @selection-change="handleSelectionChange">
      <el-table-column
          type="selection"
          align="center"
          width="55">
      </el-table-column>
      <el-table-column
          prop="title"
          label="物流名称">
        <template slot-scope="scope">
          <div class="pd-tp-5 pd-bt-10">{{scope.row.title}}</div>
        </template>
      </el-table-column>
      <el-table-column
          prop="code"
          label="物流编码"
          align="center">
      </el-table-column>
      <el-table-column
          prop="ftime"
          label="添加时间"
          align="center"
          width="160">
      </el-table-column>
    </el-table>
    <el-pagination
        background
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        layout="total, sizes, prev, pager, next, jumper"
        :page-sizes="[10, 20, 30, 40]"
        :total="search.total">
    </el-pagination>
    <el-dialog
        title="添加物流"
        :visible.sync="showModal"
        :destroy-on-close="true"
        width="500px">
      <el-form :model="form" size="small">
        <el-form-item label="选择快递" :label-width="formLabelWidth">
          <el-autocomplete v-model="form.devilery" placeholder="请填写快递名称" :fetch-suggestions="querySearchAsync" @select="handleSelect" value-key="title">
          </el-autocomplete><span class="mg-lf-10">请输入关键词搜索</span>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showModal = false" size="small">取 消</el-button>
        <el-button type="primary" @click="saveDelivery" size="small">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>

export default {
  components: {
  },
  data() {
    return {
      showModal: false,
      info: false,
      source_domain: this.config.SOURCE_DOMAIN,
      form: {
        devilery: '',
        devilery_id: 0
      },
      formLabelWidth: '80px',
      search: {
        current_page: 1,
        total: 0,
        limit: 10,
        keywords: ''
      },
      tableData: []
    };
  },
  created() {
    this.getList();
  },
  mounted() {
  },
  computed: {},
  methods: {
    getList() {
      var _this = this
      _this.showLoading()
      var param = {
        page: _this.search.current_page,
        page_size: _this.search.limit,
        keywords: _this.search.keywords
      }
      this.$api.merchant.deliveryMerchantList(param, function (res) {
        _this.hideLoading()
        if (res.errcode == 0) {
          _this.tableData = res.data.data
          _this.search.current_page = res.data.current_page
          _this.search.total = res.data.total
        } else {
          _this.fail(res.errmsg)
        }
      })
    },
    querySearchAsync(queryStr,cb) {
      this.$api.merchant.deliveryList({keywords: queryStr}, res => {
        if(res.errcode==0 && res.data && res.data.length>0) {
          var data = []
          res.data.forEach(function (item) {
            data.push({
              value: item.title,
              id: item.id
            })
          })
          cb(res.data)
        }
      })
    },
    handleSelect(e) {
      this.form.devilery_id = e.id
    },
    saveDelivery() {
      if(!this.form.devilery_id) {
        this.fail('请选择快递')
        return false
      }
      this.$api.merchant.deliveryMerchantEdit({id: this.form.devilery_id}, res => {
        if(res.errcode==0) {
          this.showModal = false
          this.success(res.errmsg)
          this.getList()
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    delGroup() {
      var that = this
      var sel = this.$refs.rfTable.selection
      if(sel.length<1) {
        that.tips('请选择删除项')
        return false
      }
      var ids = []
      sel.forEach(function (item) {
        ids.push(item.id)
      })
      this.$confirm('确认要删除这些数据吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.merchant.deliveryMerchantDel({ids: ids},function (res) {
          if(res.errcode == 0) {
            that.success('删除成功')
            that.getList()
          } else {
            that.fail(res.errmsg)
          }
        })
      }).catch(() => {

      });
    },
    handleSearch() {
      this.search.current_page = 1
      this.getList()
    },
    handleSelectionChange(rows) {
    },
    handleCurrentChange(val) {
      this.search.current_page = val
      this.getList()
    },
    handleSizeChange(val) {
      this.search.current_page = 1
      this.search.limit = val
      this.getList()
    }
  }
};
</script>
<style scoped>
</style>
