<template>
  <div class="box">
    <el-tabs v-model="tabIdx">
      <el-tab-pane label="物流设置" name="1"></el-tab-pane>
      <el-tab-pane label="物流公司" name="2"></el-tab-pane>
      <el-tab-pane label="快递接口" name="3"></el-tab-pane>
    </el-tabs>
    <el-form :model="info" label-position="right" :rules="rules" ref="ruleForm" label-width="100px" class="ruleForm" size="small" v-if="tabIdx==1">
      <el-form-item label="物流配送" prop="delivery_enabled">
        <el-switch
            v-model="info.delivery_enabled"
            active-value="1"
            inactive-value="0"
            active-text="打开"
            inactive-text="关闭">
        </el-switch>
      </el-form-item>
      <el-form-item label="物流配送别名" prop="delivery_alias">
        <el-input v-model="info.delivery_alias" placeholder="请输入别名"></el-input>
      </el-form-item>
      <el-form-item label="上门自提" prop="store_enabled">
        <el-switch
            v-model="info.store_enabled"
            active-value="1"
            inactive-value="0"
            active-text="打开"
            inactive-text="关闭">
        </el-switch>
      </el-form-item>
      <el-form-item label="上门自提别名" prop="store_alias">
        <el-input v-model="info.store_alias" placeholder="请输入别名"></el-input>
      </el-form-item>
      <el-form-item label="退货人姓名" prop="addresser_name">
        <el-input v-model="info.addresser_name" placeholder="请输入姓名"></el-input>
      </el-form-item>
      <el-form-item label="退货人电话" prop="addresser_mobile">
        <el-input v-model="info.addresser_mobile" placeholder="请输入电话"></el-input>
      </el-form-item>
      <el-form-item label="退货人地址">
        <el-input v-model="info.addresser_address" placeholder="请输入地址"></el-input>
      </el-form-item>
      <el-form-item label="不配送区域">
        <div>
          {{ info.region_string }}
        </div>
        <div>
          <el-button type="text" @click="editRegion">编辑不配送区域</el-button>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
      </el-form-item>
    </el-form>
    <delivery-list v-if="tabIdx==2"></delivery-list>
    <div v-if="tabIdx==3">
      <el-table
          :data="info.delivery_jk"
          ref="rfTable"
          size="mini">
        <el-table-column
            prop="title"
            label="名称"
            width="200">
        </el-table-column>
        <el-table-column
            prop="config"
            label="数据">
          <template slot-scope="scope">
            {{scope.row.config}}
          </template>
        </el-table-column>
        <el-table-column
            prop="status"
            label="启用"
            align="center"
            width="150">
          <template slot-scope="scope">
            <el-switch :value="scope.row.status==1" @change="changeStatus(scope.row.type,scope.row.status==1?0:1)" active-text="关闭" inactive-text="开启">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column
            prop="utime"
            label="更新时间"
            align="center"
            width="150">
        </el-table-column>
        <el-table-column
            fixed="right"
            label="操作"
            align="center"
            width="100">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="editInteface(scope.row)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
        title="选择城市"
        :visible.sync="showModal"
        :destroy-on-close="true"
        width="900px">
      <area-choose :selectedRegions="info.region_exclude" :callFunc="chooseCity"></area-choose>
    </el-dialog>
    <el-dialog
        title="编辑配置"
        :visible.sync="showSetModal"
        width="700px">
      <el-form :model="setInfo" label-position="right" :rules="rules" ref="ruleForm" label-width="100px" size="small">
        <el-form-item label="接口KEY" prop="key" v-if="setInfo.type==1">
          <el-input v-model="setInfo.config.key" placeholder="请输入密钥"></el-input>
          <div class="font12">注：接口申请前往<a href="https://www.juhe.cn/" style="color: #dc8383;" target="_blank">《聚合官网》</a>去申请</div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="saveInteface">保存</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>

import AreaChoose from "@/components/AreaChoose";
import DeliveryList from "@/components/Merchant/DeliveryList";
  export default {
    data() {
      return {
        showModal: false,
        showSetModal: false,
        setInfo: {
          type: '',
          config: {}
        },
        tabIdx: '1',
        info: {
          store_enabled: '',
          delivery_enabled: '',
          delivery_alias: '',
          store_alias: '',
          addresser_name: '',
          addresser_mobile: '',
          addresser_address: '',
          region_exclude: [3,4,5],
          region_string: ''
        },
        rules: {
        }
      };
    },
    components: {
      AreaChoose,
      DeliveryList
    },
    created() {
      this.getInfo()
    },
    mounted() {
    },
    computed: {
    },
    methods: {
      editRegion() {
        this.showModal = true
      },
      chooseCity(res) {
        this.info.region_exclude = res.cityIds
        this.info.region_string = res.cityStrings
        this.showModal = false
      },
      getInfo() {
        var _this = this
        this.$api.merchant.deliveryInfo( {},function (res) {
          if(res.errcode == 0) {
            _this.info = res.data
            _this.info.store_enabled = String(_this.info.store_enabled)
            _this.info.delivery_enabled = String(_this.info.delivery_enabled)
          }
        })
      },
      editInteface(item) {
        this.setInfo = JSON.parse(JSON.stringify(item))
        this.setInfo.config.key = ''
        this.showSetModal = true
      },
      saveInteface() {
        var params = JSON.parse(JSON.stringify(this.setInfo))
        var config = {
          key: params.config.key
        }
        if(config.key=='') {
          this.fail('请输入key')
          return false
        }
        params.config = config
        this.showLoading()
        this.$api.merchant.deliveryJkEdit(params,res=>{
          this.hideLoading()
          this.showSetModal = false
          if(res.errcode==0) {
            this.success(res.errmsg)
            this.getInfo()
          } else {
            this.fail(res.errmsg)
          }
        })
      },
      changeStatus(type,status) {
        this.$api.merchant.deliveryJkChange({type:type,status:status},res=>{
          if(res.errcode==0) {
            this.getInfo()
          } else {
            this.fail(res.errmsg)
          }
        })
      },
      submitForm(formName) {
        var _this = this
        this.$refs[formName].validate((valid) => {
          if (valid) {
            var param = _this.info
            this.$api.merchant.deliverySave(param,function (res) {
              if(res.errcode == 0) {
                _this.success(res.errmsg)
              } else {
                _this.fail(res.errmsg)
              }
            })
          } else {
            return false;
          }
        });
      },
    }
  };
</script>
<style scoped>
  .box {
    background-color: #fff;
    padding: 20px;
    border-radius: 6px;
    min-height: 500px;
  }
  .ruleForm {
    width: 600px;
  }
</style>
